import React from 'react'
import { Link } from 'gatsby'
import ResponsiveImage from 'src/components/ui/ResponsiveImage'
import type { DepartmentBannerType } from 'src/typings/sections'

import { BannerTopContainer, TextContainer } from './styled'

interface Props {
  banner: DepartmentBannerType
}

export const BannerTop = ({ banner }: Props) => {
  return (
    <BannerTopContainer>
      <ResponsiveImage
        alt={banner.alt}
        desktop={banner.desktop.srcSet}
        mobile={banner.mobile.srcSet}
      />

      <TextContainer>
        <h1>{banner.title}</h1>
        <p>{banner.description}</p>

        <div>
          <div>Que tipo de perfume está procurando?</div>

          <ul>
            <li>
              <Link to="/quiz-perfume/masculino">Masculino</Link>
            </li>
            <li>
              <Link to="/quiz-perfume/feminino">Feminino</Link>
            </li>
          </ul>
        </div>
      </TextContainer>
    </BannerTopContainer>
  )
}
