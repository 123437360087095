import React, { useState } from 'react'

import { PerfumeItem } from './styled'

interface Props {
  perfume: {
    alt: string
    href: string
    src: string
  }
}

export default function Perfume({ perfume }: Props) {
  const [showDescription, setShowDescription] = useState(false)

  const perfumeStyles = perfume.href.split(',')

  return (
    <PerfumeItem className="keen-slider__slide" isSelected={showDescription}>
      <button onClick={() => setShowDescription(!showDescription)}>
        <img src={perfume.src} alt={perfume.alt} />
        {!showDescription && <h5>{perfume.alt}</h5>}
      </button>

      {showDescription && (
        <ul>
          {perfumeStyles.map((style) => {
            const perfumeLink = style.substring(
              style.indexOf('[') + 1,
              style.lastIndexOf(']')
            )

            const [perfumeName] = style.split('[')

            return (
              <li key={perfumeName}>
                <a href={perfumeLink}>{perfumeName}</a>
              </li>
            )
          })}
        </ul>
      )}
    </PerfumeItem>
  )
}
