import { styled } from 'src/gatsby-theme-stitches/config'

export const BannerTopContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
})

export const TextContainer = styled('div', {
  color: '$onPrimary',
  fontFamily: 'Lato',
  display: 'flex',
  flexDirection: 'column',
  gap: '0.75rem',
  position: 'absolute',
  left: '10%',
  top: '50%',
  transform: 'translateY(-50%)',
  width: '31%',

  '@sm': {
    width: '85%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },

  h1: {
    textTransform: 'uppercase',
    fontSize: '2.5rem',
    lineHeight: '3rem',

    '@sm': {
      fontSize: '2rem',
    },
  },

  p: {
    fontSize: '1.25rem',
  },

  '> div': {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',

    div: {
      fontSize: '1.5rem',
      fontWeight: '700',
    },

    ul: {
      display: 'flex',
      gap: '1rem',

      a: {
        display: 'grid',
        placeItems: 'center',
        width: '7.75rem',
        height: '3.5rem',
        color: '$highlight',
        background: '$onPrimary',
        fontSize: '1rem',
        fontWeight: '600',
        borderTopLeftRadius: '10px',
        borderBottomRightRadius: '10px',
        textDecoration: 'none',
        transition: '0.3s',

        '&:hover': {
          color: '$onPrimary',
          background: '$highlight',
        },
      },
    },
  },
})
