import { styled } from 'src/gatsby-theme-stitches/config'

export const OtherWaysToSearchContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '2.5rem',
  fontFamily: 'Lato',
  mt: '2.5rem',

  '.title': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.5rem',

    img: {
      width: '3.75rem',
      height: '3.75rem',
    },

    h4: {
      textAlign: 'center',
      fontSize: '1.5rem',
      fontWeight: 'bold',
    },
  },
})

export const PerfumeContainer = styled('div', {
  padding: '3.75rem 2rem',
  linearGradient:
    '90deg,rgba(120, 3, 209, 1) 13%, rgba(221, 36, 146, 1) 53%, rgba(225, 38, 54, 1) 98%',
  display: 'flex',
  width: '100%',
  color: '$onPrimary',
  position: 'relative',
})

export const PerfumeCarousel = styled('div', {})

export const PerfumeItem = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '1.5rem',

  button: {
    all: 'unset',
  },

  img: {
    opacity: '0.6',
    transition: 'opacity 0.3s',
  },

  h5: {
    width: '85%',
    height: '2.5rem',
    backgroundColor: '$highlight',
    textTransform: 'uppercase',
    textAlign: 'center',
    fontWeight: 'bold',
    display: 'grid',
    placeItems: 'center',
    borderRadius: '5px',
    fontFamily: 'Lato',
    mx: 'auto',
    cursor: 'pointer',
  },

  a: {
    textDecoration: 'none',
    color: '$onPrimary',
    cursor: 'pointer',
  },

  variants: {
    isSelected: {
      true: {
        img: {
          opacity: '1',
        },
      },
    },
  },
})

export const ArrowsContainer = styled('div', {
  display: 'none',

  '@sm': {
    display: 'block',
  },

  button: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
    backgroundColor: '$onPrimary',
    borderRadius: '50%',
    border: '1px solid $colors$primary',
    size: '3rem',
    display: 'grid',
    placeItems: 'center',
    transition: 'all 0.2s',

    '&:first-of-type': {
      left: '1.5rem',
    },

    '&:last-of-type': {
      right: '1.5rem',
    },
  },
})
