import React from 'react'
import { Icon } from '@naturacosmeticos/natds-react'
import { useKeenSlider } from 'keen-slider/react'
import type { BannersType } from 'src/typings/sections'

import {
  ArrowsContainer,
  OtherWaysToSearchContainer,
  PerfumeCarousel,
  PerfumeContainer,
} from './styled'
import Perfume from './Perfume'

interface Props {
  perfumes: BannersType
}

export default function OtherWaysToSearch({ perfumes }: Props) {
  const [sliderRef, instanceRef] = useKeenSlider({
    initial: 0,
    slides: {
      perView: 4,
      spacing: 40,
    },
    breakpoints: {
      '(max-width: 768px)': {
        slides: {
          perView: 1,
        },
      },
    },
  })

  return (
    <OtherWaysToSearchContainer>
      <div className="title">
        <img
          src="https://www.avon.com.br/arquivos/avon-icon-search.png"
          alt="Ícone de um perfume rosa"
        />
        <h4>Outras formas de buscar</h4>
      </div>

      <PerfumeContainer>
        <PerfumeCarousel ref={sliderRef} className="keen-slider">
          {perfumes.banners.map((banner) => (
            <Perfume key={banner.alt} perfume={banner} />
          ))}
        </PerfumeCarousel>

        <ArrowsContainer>
          <button
            aria-label="Previous"
            onClick={() => instanceRef.current?.prev()}
          >
            <Icon
              name="outlined-navigation-directionright"
              color="mediumEmphasis"
            />
          </button>

          <button aria-label="Next" onClick={() => instanceRef.current?.next()}>
            <Icon
              name="outlined-navigation-directionleft"
              color="mediumEmphasis"
            />
          </button>
        </ArrowsContainer>
      </PerfumeContainer>
    </OtherWaysToSearchContainer>
  )
}
