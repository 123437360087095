import React from 'react'
import type { PageProps } from 'gatsby'
import { graphql } from 'gatsby'
import type { FC } from 'react'
import Layout from 'src/components/Layout'
import { BannerTop } from 'src/components/quiz/perfume/BannerTop'
import { Carousel } from 'src/components/quiz/perfume/Carousel'
import { Helmet } from 'react-helmet'
import OtherWaysToSearch from 'src/components/quiz/perfume/OtherWaysToSearch'

import type { QuizPerfumeQueryQuery } from './__generated__/QuizPerfumeQuery.graphql'

type Props = PageProps<QuizPerfumeQueryQuery>

const Page: FC<Props> = ({ data }) => {
  if (!data.cmsInstitutionalPage) {
    return null
  }

  const sections = data.cmsInstitutionalPage.sections
  const seo = data.cmsInstitutionalPage.seo?.siteMetadataWithSlug

  const [banner] = sections.filter(
    (section) => section.name === 'DepartmentBanner'
  )

  const [firstCarousel, secondCarousel] = sections.filter(
    (section) => section.name === 'Carousel'
  )

  const [perfumeBanners] = sections.filter(
    (section) => section.name === 'Banners'
  )

  const firstCarouselText = {
    title: 'Perfume para todos os momentos e ocasiões.',
    description:
      'Se você quer um perfume para um evento específico ou um que combine com seu estilo de vida, confira nossas diferentes categorias e encontre seu perfume Avon ideal.',
  }

  const secondCarouselText = {
    title: 'Descubra seu perfume pela sua família olfativa preferida.',
    description:
      'Nossos perfumes são compostos pelos melhores ingredientes e separados em famílias olfativas. Para ajudá-lo a escolher, clique nas setas e navegue em nossa seleção olfativa.',
  }

  return (
    <>
      <Helmet>
        <title>{seo?.title}</title>
      </Helmet>

      <Layout>
        <BannerTop banner={banner.data} />

        <Carousel
          carousel={firstCarousel.data}
          text={firstCarouselText}
          side="right"
        />

        <Carousel
          carousel={secondCarousel.data}
          text={secondCarouselText}
          side="left"
        />

        <OtherWaysToSearch perfumes={perfumeBanners.data} />
      </Layout>
    </>
  )
}

export const query = graphql`
  query QuizPerfumeQuery {
    cmsInstitutionalPage(name: { eq: "Quiz Perfume" }) {
      sections {
        name
        data
      }
      seo {
        siteMetadataWithSlug {
          title
          description
          titleTemplate
          slug
        }
      }
    }
  }
`

export default Page
