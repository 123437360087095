import React, { useState } from 'react'
import type { CarouselType } from 'src/typings/sections'
import { useKeenSlider } from 'keen-slider/react'
import { Icon } from '@naturacosmeticos/natds-react'

import {
  CarouselContainer,
  CarouselWrapper,
  SectionContainer,
  TextContainer,
} from './styled'

interface Props {
  side: 'left' | 'right'
  carousel: CarouselType
  text: {
    title: string
    description: string
  }
}

export const Carousel = ({ side, text, carousel }: Props) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [sliderRef, instanceRef] = useKeenSlider({
    loop: true,
    initial: 0,
    slides: {
      origin: 'center',
      perView: 'auto',
    },
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
  })

  const isCarouselInLeftSide = side === 'left'

  return (
    <SectionContainer isLeft={isCarouselInLeftSide}>
      <TextContainer>
        <h2>{text.title}</h2>
        <p>{text.description}</p>
      </TextContainer>

      <CarouselWrapper>
        <CarouselContainer ref={sliderRef} className="keen-slider">
          {carousel.allItems?.map((item, index) => (
            <div
              key={item.alt}
              className={`keen-slider__slide ${
                index === currentSlide ? 'active' : ''
              }`}
              style={{ maxWidth: 300, minWidth: 300 }}
            >
              <h3>{item.alt}</h3>
              <img src={item.sources[0].srcSet} alt="" />
              <a href={item.href}>Vem ver</a>
            </div>
          ))}
        </CarouselContainer>

        <div className="slider-arrow">
          <button
            aria-label="Previous"
            onClick={() => instanceRef.current?.prev()}
          >
            <Icon
              name="outlined-navigation-directionright"
              color="mediumEmphasis"
            />
          </button>

          <button aria-label="Next" onClick={() => instanceRef.current?.next()}>
            <Icon
              name="outlined-navigation-directionleft"
              color="mediumEmphasis"
            />
          </button>
        </div>
      </CarouselWrapper>
    </SectionContainer>
  )
}
