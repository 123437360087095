import { styled } from 'src/gatsby-theme-stitches/config'

export const SectionContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '3rem',
  py: '0.75rem',
  backgroundColor: '#f9fafa',

  '@sm': {
    flexDirection: 'column',
    py: '1.5rem',
  },

  variants: {
    isLeft: {
      true: {
        backgroundColor: 'transparent',
        flexDirection: 'row-reverse',

        '@sm': {
          flexDirection: 'column',
        },
      },
    },
  },
})

export const TextContainer = styled('div', {
  maxWidth: '25rem',
  px: '2.5rem',
  fontFamily: 'Lato',
  display: 'flex',
  flexDirection: 'column',
  gap: '1.5rem',

  h2: {
    fontSize: '1.5rem',
  },

  p: {
    fontSize: '14px',
    fontWeight: 'lighter',
  },
})

export const CarouselWrapper = styled('div', {
  position: 'relative',
  width: '50% !important',
  display: 'flex',
  alignItems: 'center',

  '@sm': {
    width: '100% !important',
  },
})

export const CarouselContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',

  div: {
    opacity: '0.6',
    position: 'relative',

    h3: {
      display: 'none',
    },

    img: {
      width: '18.75rem',
      height: '18.75rem',
      transform: 'scale(0.75)',
      transition: 'transform 0.5s',
    },

    a: {
      display: 'none',
    },

    '&.active': {
      opacity: '1',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '1rem',

      h3: {
        display: 'flex',
        textAlign: 'center',
        fontFamily: 'Lato',
        fontSize: '1.5rem',
        lineHeight: '1.5rem',
      },

      img: {
        transform: 'scale(1)',
      },

      a: {
        display: 'grid',
        placeItems: 'center',
        borderRadius: '5px',
        backgroundColor: '$highlight',
        color: '$onPrimary',
        width: '10rem',
        height: '2.5rem',
        fontWeight: 'bold',
        fontSize: '1rem',
        textDecoration: 'none',
        textTransform: 'uppercase',
        fontFamily: 'Lato',

        '&:hover': {
          backgroundColor: '$onPrimary',
          color: '$highlight',
          border: '1px solid $colors$highlight',
          transition: '0.4s',
        },
      },

      '&::before': {
        content: '',
        position: 'absolute',
        width: '90%',
        height: '65%',
        border: '2px solid $colors$onPrimary',
        top: '49.5%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        borderTopLeftRadius: '74px',
        borderBottomRightRadius: '74px',
        zIndex: '2',
      },
    },
  },
})
